window.addEventListener('load', () => {
    const menuButton = document.getElementById('js-menu-button');
    const menuImage = document.getElementById('js-menu-icon');
    const menu = document.getElementById('js-menu');

    let open = false;

    menuButton.addEventListener('click', () => {
        if (open) {
            menuImage.src = './images/menu-icon.svg';
            open = false;
            menu.classList.add('disabled');
        } else {
            menuImage.src = './images/close-icon.svg';
            open = true;
            menu.classList.remove('disabled');
        }
    });
});
