const countBackgroundSize = () => {
    const backgroundImageHeight = 801;
    const backgroundImageWidth = 1200;
    const headerSection = document.getElementById('js-overlay');
    if (headerSection.offsetWidth / headerSection.offsetHeight < backgroundImageWidth / backgroundImageHeight) {
        headerSection.style.backgroundSize = `auto ${headerSection.offsetHeight}px`;
    } else {
        headerSection.style.backgroundSize = `${headerSection.offsetWidth}px auto`;
    }
};

const buttonClick = () => {
    const content = document.getElementById('js-content');
    content.style.display = 'block';

    const overlay = document.getElementById('js-overlay');

    overlay.style.display = 'none';

    const video = document.getElementById('js-intro-video');
    video.style.width = 'unset';
    console.log(video);
};

window.addEventListener('resize', () => {
    if (document.getElementById('js-overlay')) {
        countBackgroundSize();
    }
});

window.addEventListener('load', () => {
    if (window.location.hash != '') {
        buttonClick();
    }

    if (document.getElementById('js-overlay')) {
        countBackgroundSize();
    }

    const webButton = document.getElementById('js-web-button');
    webButton.addEventListener('click', buttonClick);
});
