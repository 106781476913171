import * as moment from 'moment';

window.addEventListener('load', () => {
    const firstDate = moment.utc(new Date(Date.UTC(2024, 0, 29, 0, 0, 0, 0)));
    const earlyBirdDate = moment.utc(new Date(Date.UTC(2024, 2, 11, 0, 0, 0, 0)));
    const regularBirdDate = moment.utc(new Date(Date.UTC(2024, 3, 14, 22, 0, 0, 0)));
    const angryBirdDate = moment.utc(new Date(Date.UTC(2024, 3, 23, 23, 0, 0, 0)));
    const firstTicket = document.getElementById('js-first-ticket');
    const earlyBirdTicket = document.getElementById('js-early-bird');
    const earlyBirdBundleTicket = document.getElementById('js-early-bird-bundle');
    const regularBirdTicket = document.getElementById('js-regular-bird');
    const regularBirdBundleTicket = document.getElementById('js-regular-bird-bundle');
    const angryBirdTicket = document.getElementById('js-angry-bird');
    const angryBirdBundleTicket = document.getElementById('js-angry-bird-bundle');
    const countdownHeading = document.getElementById('js-countdown-heading');
    const countdown = document.getElementById('js-countdown');

    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    const disableCountdown = () => {
        countdown.style.display = 'none';
    };

    const countDiff = date => {
        const secondsDiff = moment.duration(date.diff(moment.utc())).asSeconds();
        days = Math.floor(secondsDiff / (60 * 60 * 24));
        const hoursInSeconds = Math.floor(secondsDiff % (60 * 60 * 24));
        hours = Math.floor(hoursInSeconds / 3600);
        const minutesInSeconds = hoursInSeconds % 3600;
        minutes = Math.floor(minutesInSeconds / 60);
        seconds = Math.floor(secondsDiff % 60);
    };

    const setSoldOut = ticket => {
        ticket.classList.add('sold-out');
        //ticket.querySelector('.js-order-button').innerText = 'SOLD OUT';
    };

    const setActive = ticket => {
        ticket.classList.remove('disabled');
        //ticket.querySelector('.js-order-button').innerText = 'REGISTER NOW';
        countdownHeading.innerText = 'Price increase in:';
    };

    const updateCountdown = () => {
        daysText.innerText = days < 10 ? `0${days}` : days;
        hoursText.innerText = hours < 10 ? `0${hours}` : hours;
        minutesText.innerText = minutes < 10 ? `0${minutes}` : minutes;
        secondsText.innerText = seconds < 10 ? `0${seconds}` : seconds;
    };

    const decideType = () => {
        if (days < 0) {
            setSoldOut(firstTicket);
            setActive(earlyBirdTicket);
            setActive(earlyBirdBundleTicket);
            countDiff(earlyBirdDate);
            if (days < 0) {
                setSoldOut(earlyBirdTicket);
                setSoldOut(earlyBirdBundleTicket);
                setActive(regularBirdTicket);
                setActive(regularBirdBundleTicket);
                countDiff(regularBirdDate);
                if (days < 0) {
                    setSoldOut(regularBirdTicket);
                    setSoldOut(regularBirdBundleTicket);
                    setActive(angryBirdTicket);
                    setActive(angryBirdBundleTicket);
                    countDiff(angryBirdDate);
                    if (days < 0) {
                        disableCountdown();
                    }
                }
            }
        }
    };

    console.log(days);
    countDiff(firstDate);
    decideType();

    const daysText = document.getElementById('js-days-text');
    const hoursText = document.getElementById('js-hours-text');
    const minutesText = document.getElementById('js-minutes-text');
    const secondsText = document.getElementById('js-seconds-text');

    updateCountdown();

    setInterval(() => {
        seconds--;
        if (seconds < 0) {
            minutes--;
            seconds = 59;
            if (minutes < 0) {
                hours--;
                minutes = 59;
                if (hours < 0) {
                    days--;
                    if (days < 0) {
                        decideType();
                    }
                }
            }
        }
        updateCountdown();
    }, 1000);
});
