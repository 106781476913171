window.addEventListener('load', () => {
    document.onkeydown = e => {
        const modalHashes = [
            '#petr-zvonicek-modal',
            '#liliia-abdulina-modal',
            '#chris-eidhof-modal',
            '#petr-mara-modal',
            '#lubo-smid-modal',
            '#jana-srotirova-modal',
            '#danny-preussler-modal',
            '#sebastian-aigner-modal',
            '#marton-braun-modal',
            '#lukas-camra-modal',
            '#ashley-davies-modal',
            '#james-montemagno-modal',
            '#jan-masek-modal',
            '#ahmed-tikiwa-modal',
            '#lukas-klingsbo-modal',
            '#kamil-borzym-modal',
            '#istvan-juhos-modal',
            '#mikolas-stuchlik-modal',
            '#petr-dvorak-modal',
            '#pavel-zak-modal',
            '#david-bilik-modal',
            '#igor-rosocha-modal',
            '#veronika-zelinkova-modal',
            '#michal-matl-modal',
            '#filip-dolnik-modal',
            '#christopher-lawson-modal',
            '#matej-jirasek-modal',
            '#baha-chbani-modal',
            '#marian-kamenistak-modal',
        ];
        const modalIndex = modalHashes.indexOf(window.location.hash);
        if (modalIndex !== -1) {
            if (e.key === 'ArrowRight') {
                if (modalIndex + 1 < modalHashes.length) {
                    location.hash = modalHashes[modalIndex + 1];
                }
            } else if (e.key === 'ArrowLeft') {
                if (modalIndex - 1 >= 0) {
                    location.hash = modalHashes[modalIndex - 1];
                }
            }
        }
    };
});
