import '../styles/index.scss';
import './modules/menu';
import './modules/countdown';
import './modules/endorsements';
//import './modules/top-talks';
import './modules/header';
import './modules/overlay';
import './modules/speaker-modals';
import './modules/possible-talks';
import './modules/speakers';
import './modules/topics';
import './modules/promo-video';
import '../../node_modules/remodal/dist/remodal.min.js';

if (module.hot) {
    require('webpack/hot/dev-server');
}
