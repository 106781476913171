export function arrowsController(
    previousArrowId,
    nextArrowId,
    pointsContainerId,
    collection,
    renderItem,
    getDOMElements,
) {
    let currentIndex = 0;
    let itemsCount = getDOMElements().length;

    const pointsContainer = document.getElementById(pointsContainerId);

    const previousArrow = document.getElementById(previousArrowId);
    previousArrow.addEventListener('click', () => {
        if (currentIndex > 0) {
            currentIndex--;
            renderItems();
            updatePoints();
        }
        updateArrows();
    });

    const nextArrow = document.getElementById(nextArrowId);
    nextArrow.addEventListener('click', () => {
        if (currentIndex + itemsCount < collection.length) {
            currentIndex++;
            renderItems();
            updatePoints();
        }
        updateArrows();
    });

    const updatePoints = () => {
        const count = collection.length - itemsCount + 1;
        if (currentIndex >= count) {
            currentIndex -= itemsCount - 1;
        }
        pointsContainer.innerHTML = '';
        for (let i = 0; i < count; i++) {
            pointsContainer.innerHTML += `<div class="point${i === currentIndex ? ' active' : ''}"></div>`;
        }
    };

    const updateArrows = () => {
        if (currentIndex === 0) {
            previousArrow.src = './images/previous-arrow-disabled.svg';
            previousArrow.classList.add('disabled');
        } else {
            previousArrow.src = './images/previous-arrow-enabled.svg';
            previousArrow.classList.remove('disabled');
        }
        if (currentIndex + itemsCount >= collection.length) {
            nextArrow.src = './images/next-arrow-disabled.svg';
            nextArrow.classList.add('disabled');
        } else {
            nextArrow.src = './images/next-arrow-enabled.svg';
            nextArrow.classList.remove('disabled');
        }
    };

    const renderItems = () => {
        const domElements = getDOMElements();
        for (let i = currentIndex; i < itemsCount + currentIndex; i++) {
            renderItem(domElements[i - currentIndex], collection[i]);
        }
    };

    updatePoints();
    updateArrows();

    window.addEventListener('resize', () => {
        itemsCount = getDOMElements().length;
        updatePoints();
        updateArrows();
        renderItems();
    });
}
