window.loadMoreSpeakers = () => {
    const speakersContainer = document.getElementById('js-speakers-container');
    Array.from(document.getElementById('js-speakers-container').querySelectorAll('.speaker.hide')).forEach(
        (speaker, index) => {
            if (index < 6) {
                speaker.classList.remove('hide');
            }
        },
    );

    if (speakersContainer.querySelectorAll('.speaker.hide').length === 0) {
        document.getElementById('js-load-more-speakers').style.display = 'none';
    }
};
