window.loadMoreTalks = () => {
    const talksContainer = document.getElementById('js-possible-talks');
    Array.from(document.getElementById('js-possible-talks').querySelectorAll('.talk.hide')).forEach(
        (speaker, index) => {
            if (index < 2) {
                speaker.classList.remove('hide');
            }
        },
    );

    if (talksContainer.querySelectorAll('.talk.hide').length === 0) {
        document.getElementById('js-load-more-talks').style.display = 'none';
    }
};

const talks = document.querySelectorAll('.talk');
for (let talk of talks) {
    talk.querySelector('.more').addEventListener('click', () => {
        if (!talk.querySelector('.description').classList.contains('hide')) {
            talk.querySelector('.description').classList.add('hide');
            talk.querySelector('.long-description').classList.remove('hide');
            talk.querySelector('.more').textContent = 'Less';
        } else {
            talk.querySelector('.description').classList.remove('hide');
            talk.querySelector('.long-description').classList.add('hide');
            talk.querySelector('.more').textContent = 'More';
        }
    });
}
