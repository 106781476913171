import {arrowsController} from './arrows-controller';

const tweets = [
    {
        name: 'Vadim Drobinin',
        twitter: '@Valzevul',
        text:
            'On my way home from <a href="https://twitter.com/mDevCamp" target="_blank">@mDevCamp</a> and that was beyond all expectations!<br/> Here are the slides from my talk "iOS Security 101-ish": <a href="https://drobinin.com/talks/2019/ios-security-101-ish/" target="_blank">drobinin.com/talks/2019/ios…</a>, feel free to ping me if there are any questions.<br/>Looking forward to 2020 edition!',
        tweetUrl: 'https://twitter.com/Valzevul/status/1135199728260526081',
        twitterURL: 'https://twitter.com/Valzevul',
        photoUrl: './images/endorsements/vadim_drobinin.jpg',
    },
    {
        name: 'Krzysztof Zabłocki‏',
        twitter: '@merowing_',
        text:
            'Had a blast at <a class="link" href="https://twitter.com/mDevCamp" target="_blank">@mDevCamp</a>, always a pleasure to share personal experience’s and learn from others 👌',
        tweetUrl: 'https://twitter.com/merowing_/status/1134915998694424576',
        twitterUrl: 'https://twitter.com/merowing_',
        photoUrl: './images/endorsements/krzysztof_zablocki.jpg',
    },
    {
        name: 'Aygul Zagidullina',
        twitter: '@aygul',
        text:
            'What a Friday, enjoyed every moment of it 🙌 Had a wonderful time speaking at <a class="link" href="https://twitter.com/mDevCamp" target="_blank">@mDevCamp</a>  about building for Google Assistant, meeting lots of new people and old friends 😊 Thanks a lot for having me! Also, really appreciate your childcare offer for Emily 👶 <a class="link" href="https://twitter.com/hashtag/mDevCamp?src=hashtag_click" target="_blank">#mDevCamp</a><a class="link" href="https://twitter.com/hashtag/AoGDevs?src=hashtag_click" target="_blank">#AoGDevs</a><a class="link" href="https://twitter.com/hashtag/GDE?src=hashtag_click" target="_blank">#GDE</a>',
        tweetUrl: 'https://twitter.com/aygul/status/1134892444187877377',
        twitterUrl: 'https://twitter.com/aygul',
        photoUrl: './images/endorsements/aygul_zagidullina.jpg',
    },
    {
        name: 'Jeroen Mols',
        twitter: '@molsjeroen',
        text:
            'Very grateful I was able to speak at <a class="link" href="https://twitter.com/mDevCamp" target="_blank">@mDevCamp</a>! Such a lovely and well organized conference with extremely friendly and helpful organizers. Great job <a href="https://twitter.com/search?q=%23mdevcamp&amp;src=typed_query" target="_blank">#mdevcamp</a> team! Highly recommend going there. 🙌',
        tweetUrl: 'https://twitter.com/molsjeroen/status/1134637369750171648',
        twitterUrl: 'https://twitter.com/molsjeroen',
        photoUrl: './images/endorsements/jeroen_mols.jpg',
    },
    {
        name: 'Pamela Hill',
        twitter: '@pamelaahill',
        text:
            'A big, big thank you to the organisers of <a class="link" href="https://twitter.com/mDevCamp" target="_blank">@mDevCamp</a>- it was such a great experience being part of the conference. I am so honoured that I got to speak. Also so lovely meeting some speakers <a class="link" href="https://twitter.com/ricknout" target="_blank">@ricknout</a> <a class="link" href="https://twitter.com/chriseidhof" target="_blank">@chriseidhof</a> <a class="link" href="https://twitter.com/kevcody" target="_blank">@kevcody</a> etc. and got to learn from them.',
        tweetUrl: 'https://twitter.com/pamelaahill/status/1134607910884052994',
        twitterUrl: 'https://twitter.com/pamelaahill',
        photoUrl: './images/endorsements/pamela_hill.png',
    },
    {
        name: 'Kevin Cody',
        twitter: '@kevcody',
        text:
            'Stoked to speak about <a class="link" href="https://twitter.com/fridadotre" target="_blank">@fridadotre</a> at <a class="link" href="https://twitter.com/mDevCamp" target="_blank">@mDevCamp</a> today! I had the pleasure of getting to know many of the *amazing* speakers yesterday. This event is simply stellar.',
        tweetUrl: 'https://twitter.com/kevcody/status/1134310682462048257',
        twitterUrl: 'https://twitter.com/kevcody',
        photoUrl: './images/endorsements/kevin_cody.jpg',
    },
];

const renderTweet = (endorsementDOM, tweet) => {
    endorsementDOM.innerHTML = `
<img class="endorsement-photo" src="${tweet.photoUrl}">
<h3 class="endorsement-name">${tweet.name}</h3>
<a class="link endorsement-twitter-handle" href="${tweet.twitterUrl}" target="_blank">${tweet.twitter}</a>
<p class="endorsement-text">${tweet.text}</p>
<a class="link endorsement-link" href="${tweet.tweetUrl}" target="_blank">See more</a>`;
};

const getTweets = () =>
    Array.from(document.getElementById('js-endorsements').childNodes).filter(
        it => window.getComputedStyle(it).getPropertyValue('display') !== 'none',
    );

window.addEventListener('load', () => {
    arrowsController(
        'js-endorsements-previous-arrow',
        'js-endorsements-next-arrow',
        'js-endorsements-points-container',
        tweets,
        renderTweet,
        getTweets,
    );
});
